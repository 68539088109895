<template>
  <div>
    <div class="mainDlgMask" @click="cancel"></div>
    <div id="divProjectSystemPermission" :style="getMainInputStyle()">
      <div class="mainDlgTitle">权限不足，请登录</div>
      <el-form :model="form" @submit.native.prevent>
        <el-form-item label="密 码:" :label-width="formLabelWidth">
          <el-input
            id="divProjectMainInputPassword" 
            v-model="form.value"
            autocomplete="off"
            size="mini" show-password
          ></el-input>
        </el-form-item>
        <div class="mainDlgFooter"> 
           <el-button type="primary" @click="submit()" size="mini"
            >确定</el-button
          >
          <el-button type="primary" @click="cancelClick()" size="mini"
            >取消</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
// 导入js
import HMIPrj from "../util/draw/HMIProject.js";
import HMIEvents from "../util/draw/HMIEvents.js";
import HMIPlanData from "../util/draw/HMIPlanData.js";
// 当前组件属性
export default {
  // 属性
  props: ["control" ],
  // 数据
  data: function () {
    return {
      // 对话框内容
      form: {
        // 数组
        value: '',
      },
      // 标签宽度
      formLabelWidth: "65px",
      // 对话框尺寸
      rect: {
        l: 0,
        r: 0,
        t: 0,
        b: 0,
      },
      // 样式
      mainStyle: "",
    };
  },
  // 创建
  created: function () {
    // 注册回车操作
    HMIEvents.attributes.addReturnFunc(this.onEnter);
    // 定时设置焦点
    setTimeout(
      function () {
        // 编辑框
        let vEditor = document.getElementById("divProjectMainInputPassword");
        // 设置焦点并选中
        vEditor.focus();
        vEditor.select();
      },
      300,
      null
    );
  },
  // 函数
  methods: {
    // 获取主输入框的样式位置
    getMainInputStyle: function () {
      // 检测样式是否为空
      if (this.mainStyle == "") {
        // 获取样式
        this.mainStyle = this.getMainInputPosition();
      }
      return this.mainStyle;
    },
    // 获取主输入框的样式位置
    getMainInputPosition: function () {
      // 位置样式
      let vWidth = 320, vHeight = 190;
      // 位置
      this.rect.l = (1280 - vWidth) / 2;
      this.rect.t = (650 - vHeight) / 2;
      return `left:${this.rect.l}px; top:${this.rect.t}px;`;
    },
    cancelClick(){
      this.$emit("change");
    },
    // 取消
    cancel: function (e) {
      // 检测鼠标位置
      if (
        e.clientX > this.rect.l &&
        e.clientX < this.rect.r &&
        e.clientY > this.rect.t &&
        e.clientY < this.rect.b
      ) {
        return;
      }

      // 改变数据值
      this.$emit("change");
      // 移除回车操作
      HMIEvents.attributes.removeReturnFunc();
    },
    // 确定
    submit: function () {
      // 比较密码
      for(let idx = HMIPrj.vHMIPrj.sysPermissions.length - 1; idx >= 0; idx--) {
        // 比较密码是否一致
        if (HMIPrj.vHMIPrj.sysPermissions[idx] == this.form.value) {
          // 权限设置
          HMIPrj.vHMIPrj.curLoginPermission = idx+1;
          // 设置内部参数值
          HMIPrj.vHMIPrjFunc.setCtrlsInsideData([ HMIPlanData.PlanDataInsideParamKeys.insideParamValue, 
                                                  HMIPlanData.PlanDataInsideParamKeys.insideRegisterValue,
                                                  HMIPlanData.PlanDataInsideParamKeys.insideRegVal_W807 ],
                                                HMIPrj.vHMIPrj.curLoginPermission);
          break;
        }
      }
      // 改变数据值
      this.$emit("change");
      // 移除回车操作
      HMIEvents.attributes.removeReturnFunc();
    },
    // 回车操作
    onEnter: function (vSourceCtrl) {
      // 检查控件是否为编辑框
      if (!vSourceCtrl) {
        return;
      }
      if (vSourceCtrl.tagName.toLowerCase() != "input") {
        return;
      }
      // 执行确定操作
      this.submit();
    },
  },
};
</script>

<style scoped>

::v-deep .el-input__inner{
  color: #000 !important;
  width: 100% !important;
}
.mainDlgFooter{
  height: 96px;
  margin-top: 10px;
}
</style>
