<template>
  <div id="prj-scrollbar-h" :class="scrollbarClass" v-on:scroll="scrollWnd">
    <div id="prj-scrollbar-h-detail" class="scrollbarHDetail" />
  </div>
</template>
<script>
// 导入js
import HMIEvents from "../util/draw/HMIEvents.js";
import HG from '../util/draw/HMIGlobal.js';
import gDrawHandle from "../util/draw/HMIDraw.js";
// 当前组件属性
export default {
  // 属性
  props: ["edit"],
  // 数据
  data: function () {
    return {
      // 缩放率
      lastZoomRate: 1,
      // scrollbar control ID
      scrollbarId: 'prj-scrollbar-h',
      // scrollbar control detail ID
      scrollbarDetailId: 'prj-scrollbar-h-detail',
      // 编辑模式
      editMode: false,
      // 显示样式
      scrollbarClass: 'scrollbarH',
    };
  },
  // 创建
  created: function () {
    // 检查是否为编辑模式
    if(this.edit == 'true') {
      // 编辑模式
      this.editMode = true;
      // 显示样式
      this.scrollbarClass = 'scrollbarH';
    } else {
      // 显示模式
      this.editMode = false;
      // 显示样式
      this.scrollbarClass = 'scrollbarHExe';
    }
    // 初始化等待后绘制
    setTimeout(this.init, 200);
  },
  // 函数
  methods: {
    // 初始化完成
    init: function() {
      // 注册窗口大小改变事件
      HMIEvents.attributes.addResizeFunc(this.wndSizeChanged);
      // 注册鼠标中键滚动改变事件
      HMIEvents.attributes.addWheelFunc(this.mouseWheelChanged);
      // 设置窗口改变
      this.wndSizeChanged(window.innerWidth, window.innerHeight);
      // 刷新滚动条
      setTimeout(this.refreshScrollBar, 500);
    },
    // 窗口改变尺寸
    wndSizeChanged: function (width, height) {
      // 获取对象
      let vScrollbarH       = document.getElementById(this.scrollbarId);
      let vScrollbarHDetail = document.getElementById(this.scrollbarDetailId);
      // 宽度
      let vCtrlWidth = 0;
      
      // 检查是否有效
      if(vScrollbarH) {
        // 检查是否为编辑模式
        if(this.editMode) {
          // 计算滚动条尺寸
          vCtrlWidth = width - HG.$Set.allSettings.scrollSize.scrollHMargin;
        }
        else {
          // 计算滚动条尺寸
          vCtrlWidth = width - HG.$Set.allSettings.scrollSize.scrollHMarginExe;
        }
        // 检查是否大于有效值
        if((vCtrlWidth > 100) && (height > 50)) {
          // 设置宽度
          vScrollbarH.style.width         = vCtrlWidth + 'px';
          // 显示
          vScrollbarH.style.display       = 'block';
          // 设置
          vScrollbarHDetail.style.width   = gDrawHandle.settings.zoomPrjRateW * vCtrlWidth + 'px';
          // 隐藏
          vScrollbarHDetail.style.display = 'block';
        }
        else {
          // 隐藏
          vScrollbarH.style.display       = 'none';
          // 隐藏
          vScrollbarHDetail.style.display = 'none';
        }

        // 缩放率
        this.lastZoomRate = gDrawHandle.settings.zoomPrjRateW;
      }
    },
    // 窗口滚动
    scrollWnd: function () {
      // 获取对象
      let vScrollbarH = document.getElementById(this.scrollbarId);
      // 滚动位置
      gDrawHandle.settings.scrollPosLeftRate = vScrollbarH.scrollLeft / vScrollbarH.scrollWidth;
      // 设置为活动状态
      gDrawHandle.setEventActive();
    },
    // 刷新滚动条
    refreshScrollBar: function () {
      // 缩放率
      if(this.lastZoomRate != gDrawHandle.settings.zoomPrjRateW) {
        // 获取对象
        let vScrollbarH       = document.getElementById(this.scrollbarId);
        let vScrollbarHDetail = document.getElementById(this.scrollbarDetailId);
        // 宽度
        let vCtrlWidth = 0;
        
        // 检查是否有效
        if(vScrollbarH) {
            // 设置宽度
          vCtrlWidth = parseInt(vScrollbarH.style.width);
          // 设置
          vScrollbarHDetail.style.width   = gDrawHandle.settings.zoomPrjRateW * vCtrlWidth + 'px';
        }
        // 缩放率
        this.lastZoomRate = gDrawHandle.settings.zoomPrjRateW;
      }
      // 刷新绘制
      requestAnimationFrame(this.refreshScrollBar);
    },
    // 鼠标中键滚动 
    mouseWheelChanged: function(position, shiftDown) {
      // 获取对象
      let vScrollbarH   = document.getElementById(this.scrollbarId);
      // 滚动位置
      let vScrollPos    = vScrollbarH.scrollLeft;
      // 获取对象
      let vScrollWidth  = vScrollbarH.scrollWidth;
      let vClientWidth  = vScrollbarH.clientWidth;

      // 检查Shift是否按下
      if(shiftDown) {
        // 滚动位置改变
        vScrollPos += position;
        // 检查滚动位置
        if(vScrollPos < 0) {
          vScrollPos = 0;
        }
        // 检查滚动位置
        if(vScrollPos + vClientWidth > vScrollWidth) {
          vScrollPos = vScrollWidth - vClientWidth;
        }
        // 检查位置是否改变
        if(vScrollPos != vScrollbarH.scrollLeft) {
          // 滚动位置
          vScrollbarH.scroll(vScrollPos, 0);
        }
      }
    },
  },
};
</script>
