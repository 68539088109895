<template>
  <div>
    <div class="mainDlgMask" @click="cancel"></div>
    <!-- :style="getMainInputStyle()" -->
    <div id="divProjectMainInput" :style="getMainInputStyle()">
      <div class="mainDlgTitle">数据编辑</div>
      <el-form :model="form" @submit.native.prevent>
        <el-form-item label="输入值:" :label-width="formLabelWidth">
          <el-input
            id="divProjectMainInputEditor"
            v-model="form.value"
            autocomplete="off"
            size="mini"
          ></el-input>
        </el-form-item>
        <div class="mainDlgFooter"> 
           <el-button type="primary" @click="submit()" size="mini"
            >确定</el-button
          >
          <el-button type="primary" @click="cancelClick()" size="mini"
            >取消</el-button
          >
        
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
// 导入js
// import gDrawHandle from "../util/draw/HMIDraw.js";
import HMIDataPool from "../util/draw/HMIDataPool.js";
import HMIEvents from "../util/draw/HMIEvents.js";
import HMIPrj from "../util/draw/HMIProject.js";
import { Message,Loading } from 'element-ui';

// import HMIEchoData from "../util/draw/HMIEchoData";
// 当前组件属性
export default {
  // 属性
  props: ["control", "value"],
  // 数据
  data: function () {
    return {
      // 对话框内容
      form: {
        // 数组
        value:this.value,
      },
      // 标签宽度
      formLabelWidth: "65px",
      // 对话框尺寸
      rect: {
        l: 0,
        r: 0,
        t: 0,
        b: 0,
      },
      // 样式
      mainStyle: "",
    };
  },
  // 创建
  created: function () {
    // 注册回车操作
    HMIEvents.attributes.addReturnFunc(this.onEnter);
    // 定时设置焦点
    setTimeout(
      function () {
        // 编辑框
        let vEditor = document.getElementById("divProjectMainInputEditor");
        // 设置焦点并选中
        vEditor.focus();
        vEditor.select();
      },
      300,
      null
    );
  },
  // 函数
  methods: {
    
    // 获取主输入框的样式位置
    getMainInputStyle: function () {
      // 检测样式是否为空
      if (this.mainStyle == "") {
        // 获取样式
        this.mainStyle = this.getMainInputPosition();
      }
      return this.mainStyle;
    },
    // 获取主输入框的样式位置
    getMainInputPosition: function () {
      // 位置样式
      let vWidth = 320,
        vHeight = 190;

      // 检查控件
      // if (this.control) {
      //   // 位置样式
      //   this.rect.l = this.control.x;
      //   this.rect.r = this.control.x + this.control.w;
      //   this.rect.t = this.control.y;
      //   this.rect.b = this.control.y + this.control.h;
      //   // 位置计算
      //   this.rect.l -=
      //     gDrawHandle.settings.scrollPosLeftRate * gDrawHandle.settings.width;
      //   this.rect.t -=
      //     gDrawHandle.settings.scrollPosTopRate * gDrawHandle.settings.height;
      //   this.rect.r -=
      //     gDrawHandle.settings.scrollPosLeftRate * gDrawHandle.settings.width;
      //   this.rect.b -=
      //     gDrawHandle.settings.scrollPosTopRate * gDrawHandle.settings.height;
      //   // 控件位置
      //   this.rect.l /= gDrawHandle.getRealSizeRate();
      //   this.rect.r /= gDrawHandle.getRealSizeRate();
      //   this.rect.t /= gDrawHandle.getRealSizeRate();
      //   this.rect.b /= gDrawHandle.getRealSizeRate();
      //   // 检查位置
      //   if (this.rect.l > window.innerWidth / 2) {
      //     // 计算位置
      //     this.rect.l = this.rect.r - vWidth;
      //   }
      //   // 检查位置
      //   if (this.rect.t > window.innerHeight / 2) {
      //     // 计算位置
      //     this.rect.t = this.rect.t - vHeight - 2;
      //   } else {
      //     // 计算位置
      //     this.rect.t = this.rect.b + 2;
      //   }
      // } else {
      //   // 计算
      //   this.rect.l = (window.innerWidth - vWidth) / 2;
      //   this.rect.t = (window.innerHeight - vHeight) / 2;
      // }
      // // 计算偏移位置
      // this.rect.l += gDrawHandle.rectCanvasArea.l;
      // this.rect.t += gDrawHandle.rectCanvasArea.t;
      // // 计算
      // this.rect.r = this.rect.l + vWidth;
      // this.rect.b = this.rect.t + vHeight;
      // 位置
      this.rect.l = (1280 - vWidth) / 2;
      this.rect.t = (650 - vHeight) / 2;
      return `left:${this.rect.l}px; top:${this.rect.t}px;`;
    },
    cancelClick(){
this.$emit("change");
    },
    // 取消
    cancel: function (e) {
      // 检测鼠标位置
      if (
        e.clientX > this.rect.l &&
        e.clientX < this.rect.r &&
        e.clientY > this.rect.t &&
        e.clientY < this.rect.b
      ) {
        return;
      }

      // 改变数据值
      this.$emit("change");
      // 移除回车操作
      HMIEvents.attributes.removeReturnFunc();
    },
    // 确定
    submit: function () {
      if (this.control.param[1]) {
        
      }
      let _d = {
        projectId: this.$store.state.projectId,
        gatewayId: this.control.param[0],
        subOriginalId: this.control.param[1],
        paramId: this.control.param[2],
        value: Number(this.form.value),
        page:HMIPrj.vHMIPrj.curPg*1+1
      };
      let loadingInstance = Loading.service({
                        lock: true,
                        text: '下发中，请稍后...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(255, 255, 255, 0.5)',
            target:document.querySelector('.el-dialog__body')
                    });
      this.$api.project.sendData(_d).then((response) => {
        loadingInstance.close()
        if (response.code == 200) {
          // 改变数据值
          this.$emit("change");
          // 移除回车操作
          HMIEvents.attributes.removeReturnFunc();
          // 输入框的关联控件检查
          if (this.control) {
            // 执行数据操作
            HMIDataPool.setCOMMData(this.control.param, this.form.value);
          }

          // HMIEchoData.wsData[this.control.param[2]].currentValue = Number(
          //   this.form.value
          // );
         
          this.$message({
            type: "success",
            message: "动作已提交，请等待数据更新，勿重复点击！",
          });
        } else {
          // this.$message({
          //   type: "warning",
          //   message: "下发失败，请重试!",
          // });
        }
      });
    },
    // 回车操作
    onEnter: function (vSourceCtrl) {
      // 检查控件是否为编辑框
      if (!vSourceCtrl) {
        return;
      }
      if (vSourceCtrl.tagName.toLowerCase() != "input") {
        return;
      }
      // 执行确定操作
      this.submit();
    },
  },
};
</script>

<style scoped>

::v-deep .el-input__inner{
  color: #000 !important;
  width: 100% !important;
}
.mainDlgFooter{
  height: 96px;
  margin-top: 10px;
}
</style>
