<template>
  <div id="prj-scrollbar-v" :class="scrollbarClass" v-on:scroll="scrollWnd">
    <div id="prj-scrollbar-v-detail" class="scrollbarVDetail" />
  </div>
</template>
<script>
// 导入js
import HMIEvents from "../util/draw/HMIEvents.js";
import HG from '../util/draw/HMIGlobal.js';
import gDrawHandle from "../util/draw/HMIDraw.js";
// 当前组件属性
export default {
  // 属性
  props: ["edit"],
  // 数据
  data: function () {
    return {
      // 缩放率
      lastZoomRate: 1,
      // scrollbar control ID
      scrollbarId: 'prj-scrollbar-v',
      // scrollbar control detail ID
      scrollbarDetailId: 'prj-scrollbar-v-detail',
      // 编辑模式
      editMode: false,
      // 显示样式
      scrollbarClass: 'scrollbarV',
    };
  },
  // 创建
  created: function () {
    // 检查是否为编辑模式
    if(this.edit == 'true') {
      // 编辑模式
      this.editMode = true;
      // 显示样式
      this.scrollbarClass = 'scrollbarV';
    } else {
      // 显示模式
      this.editMode = false;
      // 显示样式
      this.scrollbarClass = 'scrollbarVExe';
    }
    // 初始化等待后绘制
    setTimeout(this.init, 200);
  },
  // 函数
  methods: {
    // 初始化完成
    init: function() {
      // 注册窗口大小改变事件
      HMIEvents.attributes.addResizeFunc(this.wndSizeChanged);
      // 注册鼠标中键滚动改变事件
      HMIEvents.attributes.addWheelFunc(this.mouseWheelChanged);
      // 设置窗口改变
      this.wndSizeChanged(window.innerWidth, window.innerHeight);
      // 刷新滚动条
      setTimeout(this.refreshScrollBar, 500);
    },
    // 窗口改变尺寸
    wndSizeChanged: function (width, height) {
      // 获取对象
      let vScrollbarV = document.getElementById(this.scrollbarId);
      let vScrollbarVDetail = document.getElementById(this.scrollbarDetailId);
      // 高度
      let vCtrlHeight = 0;
            
      // 检查是否有效
      if(vScrollbarV) {
        // 检查是否为编辑模式
        if(this.editMode) {
          // 计算滚动条尺寸
          vCtrlHeight = height - HG.$Set.allSettings.scrollSize.scrollVMargin;
        } else {
          // 计算滚动条尺寸
          vCtrlHeight = height - HG.$Set.allSettings.scrollSize.scrollVMarginExe;
        }
        // 检查是否大于有效值
        if ((vCtrlHeight > 100) && (width > 480)) {
          // 设置宽度
          vScrollbarV.style.height = vCtrlHeight + 'px';
          // 显示
          vScrollbarV.style.display = 'block';
          // 设置
          vScrollbarVDetail.style.height = this.zoomRate * vCtrlHeight + 'px';
          // 显示
          vScrollbarVDetail.style.display = 'block';
        }
        else {
          // 隐藏
          vScrollbarV.style.display = 'none';
          // 隐藏
          vScrollbarVDetail.style.display = 'none';
        }
      }
    },
    // 窗口滚动
    scrollWnd: function () {
      // 获取对象
      let vScrollbarV = document.getElementById(this.scrollbarId);
      // 滚动位置
      gDrawHandle.settings.scrollPosTopRate = vScrollbarV.scrollTop / vScrollbarV.scrollHeight;
      // 设置为活动状态
      gDrawHandle.setEventActive();
    },
    // 刷新滚动条
    refreshScrollBar: function () {
      // 缩放率
      if(this.lastZoomRate != gDrawHandle.settings.zoomPrjRateW) {
        // 获取对象
        let vScrollbarV = document.getElementById(this.scrollbarId);
        let vScrollbarVDetail = document.getElementById(this.scrollbarDetailId);
        // 高度
        let vCtrlHeight = 0;
        
        // 检查是否有效
        if(vScrollbarV) {
            // 设置宽度
          vCtrlHeight = parseInt(vScrollbarV.style.height);
          // 设置
          vScrollbarVDetail.style.height   = gDrawHandle.settings.zoomPrjRateH * vCtrlHeight + 'px';
        }
        // 缩放率
        this.lastZoomRate = gDrawHandle.settings.zoomPrjRateH;
      }
      // 刷新绘制
      requestAnimationFrame(this.refreshScrollBar);
    },
    // 鼠标中键滚动 
    mouseWheelChanged: function(position, shiftDown) {
      // 获取对象
      let vScrollbarV   = document.getElementById(this.scrollbarId);
      // 滚动位置
      let vScrollPos    = vScrollbarV.scrollTop;
      // 获取对象
      let vScrollHeight = vScrollbarV.scrollHeight;
      let vClientHeight = vScrollbarV.clientHeight;

      // 检查Shift是否按下
      if(!shiftDown) {
        // 滚动位置改变
        vScrollPos += position;
        // 检查滚动位置
        if(vScrollPos < 0) {
          vScrollPos = 0;
        }
        // 检查滚动位置
        if(vScrollPos + vClientHeight > vScrollHeight) {
          vScrollPos = vScrollHeight - vClientHeight;
        }
        // 检查位置是否改变
        if(vScrollPos != vScrollbarV.scrollTop) {
          // 滚动位置
          vScrollbarV.scroll(0, vScrollPos);
        }
      }
    },
  },
};
</script>
