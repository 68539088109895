<template>
  <div id="prj-draw-area" role="editor" :class="prjDrawClass">
    <HMIInputEditor
      v-if="edtVisible"
      :control="editorLinkedCtrl"
      :value="editorValue"
      v-on:change="changeValue"
    ></HMIInputEditor>
    <HMIInputPassword v-if="sysPwdVisible" 
                      :control="sysPwdLinkedCtrl" 
                      v-on:change="changeValue"></HMIInputPassword>
    <!-- <HMIHistoryDatePicker
      v-if="historyDialog"
      :control="editorLinkedCtrl"
      :value="editorValue"
      v-on:change="changeValue"
    ></HMIHistoryDatePicker> -->
  </div>
</template>
<script>
// 导入js
import HG from "../util/draw/HMIGlobal.js";
import HMIEvents from "../util/draw/HMIEvents.js";
import HMIPrj from "../util/draw/HMIProject.js";
import gDrawHandle from "../util/draw/HMIDraw.js";
import HMIPlan from "../util/draw/HMIPlanData.js";
// 导入组件
import HMIInputEditor from "./HMIInputEditor.vue";
import HMIInputPassword from "./HMIInputPassword.vue";
import HMIExterCtrls from "../util/draw/HMIExterCtrls"
// import HMIHistoryDatePicker from "./HMIHistoryDatePicker.vue";
let that;
// 当前组件属性
export default {
  // 组件名
  name: "HMIDrawArea",
  // 属性
  props: ["edit", "insideMode"],
  // 创建
  created: function () {
    that = this;
    // 检查是否为编辑模式
    if (that.edit == "true") {
      // 编辑模式
      that.editMode = true;
      // 显示样式
      that.prjDrawClass = "drawDiv";
      // 检查是否为真
      if (that.insideMode == "true") {
        // 显示样式
        that.prjDrawClass = "drawDivInside";
        // 内部时设置显示画布的背景色
        gDrawHandle.dspCanvasBgColor = "#C4C4C4";
      }
    } else {
      // 编辑模式
      that.editMode = false;
      // 显示样式
      that.prjDrawClass = "executeDiv";
    }

    // 清除Plan数据
    HMIPlan.resetData();
    // 设置初始状态
    gDrawHandle.initSuccess = false;
    // 注册窗口事件
    HMIEvents.events.regEvent(that.editMode);
    HMIPrj.vHMIPrjFunc.historyHandler = that.historyHandler;
    // 刷新绘制
    that.refreshDraw();
    // gDrawHandle.setZoom();
  },
  // 数据
  data: function () {
    return {
      // 编辑模式
      editMode: false,
      // 显示样式
      prjDrawClass: "drawDiv",
      //历史曲线时间选择器显隐
      historyDialog: false,
      // 输入框的显示
      edtVisible: false,
      // 输入框的关联控件
      editorLinkedCtrl: null,
      // 输入框的值
      editorValue: 0,
      
      // 系统密码输入框的显示
      sysPwdVisible: false,
      // 系统密码输入框的关联控件
      sysPwdLinkedCtrl: null,

      timer: null,
    };
  },
  // 函数
  methods: {
    // 绘制回调
    drawPrjFn: function (selectedCnt, refreshDraw, drawStatus) {
      // 选中控件改变
      that.$emit("selectCtrlChanged", selectedCnt, refreshDraw, drawStatus);
    },
    // 绘制整个画面
    drawProject: function () {
      // 选中控件数量
      let ctrlSelectedCnt = 0;
      let drawStatus = { pageChanged: false };

      // 编辑模式
      if (!that.editMode) {
        // 在执行模式下，设置输入控件句柄
        if (null == HMIPrj.vHMIPrjFunc.inputControlHandler) {
          // 设置函数句柄
          HMIPrj.vHMIPrjFunc.inputControlHandler = that.inputControl;
        }
        // 在执行模式下，设置系统权限句柄
        if (null == HMIPrj.vHMIPrjFunc.systemPermissionHandler) {
          // 设置函数句柄
          HMIPrj.vHMIPrjFunc.systemPermissionHandler = that.systemPermissionHandler;
        }
        HMIExterCtrls.input=that.inputControl;
      }

      // 检查是否初始化
      if (!gDrawHandle.initSuccess) {
        // 绘制开始
        gDrawHandle.init("prj-draw-area");
        // 初始化完成
        gDrawHandle.initSuccess = true;
        // 清空 临时用下拉列表关联控件指针
        HMIPrj.vHMIPrj.curDdlLinked = { ctrl: null, x: 0, y: 0, w: 0, h: 0, start: 0, max: 0 };
      } else {
        // 检查显示缩放率是否改变
        if (gDrawHandle.getAndResetDisplayZoomChanged()) {
          // 执行
          that.edtVisible = false;
        }
        // 绘制前准备
        gDrawHandle.onPrepareDraw();
        // 绘制背景
        gDrawHandle.drawBackground(HMIPrj.vHMIPrj);  
        // 刷新绘制控件 并获取选中控件的数量
        ctrlSelectedCnt = gDrawHandle.drawCtrls(HMIPrj.vHMIPrj, drawStatus);
        // 绘制缓存
        gDrawHandle.drawBuffer();

        // 绘制结束
        that.drawPrjFn(ctrlSelectedCnt, HMIPrj.vHMIPrj.refreshDraw, drawStatus);
        // 刷新绘制
        HMIPrj.vHMIPrj.refreshDraw = false;
      }
      // 刷新绘制
      // requestAnimationFrame(that.refreshDraw);
    },
    // 刷新绘制
    // refreshDraw: function () {
    //   // 按照帧数绘制 定时刷新
    //   setTimeout(that.drawProject, gDrawHandle.getFpsInterval());
    // },
    refreshDraw: function () {
      this.timer = setInterval(that.drawProject, gDrawHandle.getFpsInterval(),1000);
    },
    // 控件点击后显示输入框
    inputControl: function (ctrl) {
      // 检查控件类型
      switch (ctrl.type) {
        case HG.ControlType.num:
          // 输入框的关联控件
          that.editorLinkedCtrl = ctrl;
          // 输入框的值
          that.editorValue = ctrl.dataValue;
          // 执行
          that.edtVisible = true;
          break;
      }
      if (ctrl.typeValue=="true") {
         // 输入框的关联控件
          that.editorLinkedCtrl = ctrl;
          // 输入框的值
          that.editorValue = ctrl.value;
          // 执行
          that.edtVisible = true;
      }
      
    },
    //-------------------------------------------------------------------------
    // 系统权限输入框
    //
    systemPermissionHandler: function (ctrl) {
      // 检查控件类型
      switch (ctrl.type) {
        case HG.ControlType.bbtn:
        case HG.ControlType.wbtn:
        case HG.ControlType.pbtn:
        case HG.ControlType.num:
          // 系统密码输入框的关联控件
          that.sysPwdLinkedCtrl = ctrl;
          // 执行
          that.sysPwdVisible = true;
          break;
      }
      if (ctrl.typeValue=="true") {
          // 系统密码输入框的关联控件
          that.sysPwdLinkedCtrl = ctrl;
          // 执行
          that.sysPwdVisible = true;
      }
    },

    historyHandler: function () {
      that.historyDialog = true;
    },
    // 输入内容改变
    changeValue: function () {
      // 执行
      that.edtVisible = false;
      // 执行隐藏
      that.sysPwdVisible = false;
    },
  },
  beforeDestroy: function () {
    // 清除登录权限
    HMIPrj.vHMIPrj.curLoginPermission = 0;
    // console.log(HMIPrj.vHMIPrj.allPg);
    HMIPrj.vHMIPrj.allPg[0].ctrls=[];
    clearInterval(this.timer);
  },
  // 组件
  components: {
    HMIInputEditor,
    HMIInputPassword,
    // HMIHistoryDatePicker,
  },
};
</script>
